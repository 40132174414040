import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {CompanyModel} from '../models/CompanyModel'
import { getCompany } from './CompanyApi'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  CompanyRequested: '[Request Company] Action',
  CompanyLoaded: '[Load Company] Company API',
}

const initialState: IState = {
  company: undefined,
}

export interface IState {
  company?: CompanyModel
}

export const reducer = persistReducer(
  {storage, key: 'data-company', whitelist: ['company']},
  (state: IState = initialState, action: ActionWithPayload<IState>) => {
    switch (action.type) {

      case actionTypes.CompanyRequested: {
        return {...state, company: undefined}
      }

      case actionTypes.CompanyLoaded: {
        const company = action.payload?.company
        return {...state, company}
      }

      default:
        return state
    }
  }
)

export const actions = {
  requestCompany: () => ({
    type: actionTypes.CompanyRequested,
  }),
  fulfillCompany: (company: CompanyModel) => ({type: actionTypes.CompanyLoaded, payload: {company}}),
}

export function* saga() {
  yield takeLatest(actionTypes.CompanyRequested, function* companyRequested() {
    const {data: company} = yield getCompany()
    yield put(actions.fulfillCompany(company))
  })
}
