import axios from 'axios'

// export const URL = `${process.env.REACT_APP_API_URL}laporan`
export const URL = `workflow`

export function ApproveDokumenWorkflow(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/approval/approve-dokumen/'+id, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchNotificationTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/notification/show-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function ShowNotificationDetail(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/notification/show/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function ShowNotificationCount(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/notification/count', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchLogs(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL + '/notification/show-log', params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

