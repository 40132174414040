import axios from 'axios'

export const URL = `md-proposal`
export const URL_TENDER = `tender`

export const FetchTenderAsset = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/asset/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const VoidProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/void', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const PublishPemenangProposalTender = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/pemenang/publish', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowPengumumanPemenangTender = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/pemenang/pengumuman', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowDashboardTender = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/dashboard/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchEdoc(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-referensi', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchAgenda(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/agenda/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchMasterProposal(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchProposalTender(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-proposal', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function ShowProposalTender(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-detail-proposal/'+id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchDetailProposalTender(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-detail-proposal/'+id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchDetailProposalTenderVendor(id:any, params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-detail-proposal-tender/'+id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchTemplateProposal(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-template-proposal', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchPRProposal(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-pr', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/destroy', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const PublishProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/publish', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UnpublishProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/unpublish', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SubmitProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/submit', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ApproveProposalTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/approve', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchItemPersyaratanTeknisTable(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-item-teknis-table', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchItemPersyaratanTeknis(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL+ '/show-item-teknis', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const CreateItemPersyaratanTeknis = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/store-item-teknis', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UpdateItemPersyaratanTeknis = (id: any, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/update-item-teknis/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const DeleteItemPersyaratanTeknis = (id: any, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL+ '/delete-item-teknis/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchJoinProposalTender = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-join-tender', {params:params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchJoinProposalTenderVendor = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-list-tender', {params:params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const JoinProposalTender = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/join', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchPesertaJoinTender = (id:any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-peserta/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchDetailPesertaJoinTender = (id:any, id2:any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-dokumen-peserta/' + id + '/' + id2, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowDetailPesertaJoinTender = (id:any, id2:any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-dokumen-peserta/' + id + '/' + id2, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const VerifyJoinTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/verifikasi-join-tender', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UnverifyJoinTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/unverifikasi-join-tender', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchTerminPembayaran = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/termin-pembayaran/show', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchTerminPembayaranPerTender = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/termin-pembayaran/show-detil/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SaveTerminPembayaran = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });

    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/termin-pembayaran/store', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function InvitePembukaanPenawaran(payload: any) {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/kirim-undangan-pembukaan', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function InviteKlarifikasiPenawaran(payload: any) {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/kirim-undangan-klarifikasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchPenawaranTender = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-tender', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchDetailPenawaranTender = (id: any, params:any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-penawaran/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowPenawaranTender = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta-penawaran-awal/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SaveDokumenPenawaran = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/kirim-penawaran', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchTenderPenawaran = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-tender-penawaran', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchDokumenPenawaranTender = (id: any, id2: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta-tender/' + id + '/' + id2, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchDokumenPenawaranTenderByVendor = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta-tender/' + id , {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const VerifikasiDokumenPenawaran = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/verifikasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchPembukaanPenawaran = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-tender-pembukaan-dokumen', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchPesertaPembukaanDokumen = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta-pembukaan-dokumen/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchBuktiPembukaanPenawaran = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-jadwal-tender-penawaran', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchBuktiPembukaanNegosiasi = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-jadwal-tender-negosiasi', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const KirimBaBuktiPembukaan = (id:any, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/penawaran/kirim-ba-pembukaan/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const StoreBuktiPembukaan = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/penawaran/store-bukti-pembukaan', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function InviteBeautyContest(payload: any) {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/beauty-contest/kirim-undangan', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchBuktiBeautyContest = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/beauty-contest/show-jadwal', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const StoreBuktiBeautyContest = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/beauty-contest/store-bukti', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowPenawaranPesertaAdmin = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta-tender-negosiasi/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowListPeserta = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowListPemenang = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penilaian/show-pemenang/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowListEvaluasi = (id: any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/evaluasi/show-pemenang/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const PenilaianVendor = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penilaian/vendor', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const BatalPenilaianVendor = (id: any, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penilaian/batal/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const PenyelesaianTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penyelesaian/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SetPemenangTender = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penilaian/pemenang', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const UploadSuratPesanan = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penilaian/upload-pesanan', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchPemenangIndex = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penilaian/show-proposal', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchEvaluasiIndex = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/evaluasi/show-proposal', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const StoreBuktiNegosiasi = (payload: any) => {
    let formData = new FormData();

    Object.keys(payload).forEach(function(key) {
        formData.append(key, payload[key])
    });
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER + '/penawaran/store-bukti-negosiasi', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowTenderNegosiasiPenawaran = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-tender-negosiasi-penawaran', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const KirimNegosiasi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/kirim-negosiasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SubmitNegosiasi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/submit-negosiasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const VerifNegosiasi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/verifikasi-negosiasi', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowRiwayatVendor = (id:any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-evaluasi-peserta/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ShowEvaluasi = (id:any, id2:any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/evaluasi/show-detail-proposal/' +  id + '/' + id2, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SaveEvaluasi = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/evaluasi/store', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const SetLink = (id:any, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/set-link/' + id, payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchMonitorPekerjaan = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/monitoring-pekerjaan', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchPesertaOther = (id:any, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-peserta-penawaran/' + id, {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchPesertaJoin(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/show-list-peserta', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchLevelResiko(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL + '/show-level-resiko', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export function FetchItemHse(params: any) {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/penawaran/show-item-hse', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const VerifikasiCsms = (payload: any) => {
    return new Promise((resolve, reject) => {
        axios.post(URL_TENDER+ '/penawaran/verifikasi-hse', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchProgressPekerjaan = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER + '/progress-pekerjaan', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchLaporanVendor = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER + '/laporan/vendor', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const ExportLaporanVendor = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER + '/laporan/eksport-vendor', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

export const FetchSuratPesanan = (params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(URL_TENDER+ '/pemenang/surat-pesanan', {params: params})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    })
}

