/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC,
  // useState, useEffect
} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import i18next from "i18next";
import { DashboardPelamarWrapper } from './DashboardPelamarWrapper';
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import { DashboardVendor } from './DashboardVendor';
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const {t} = i18next

const DashboardPage = () => {
  // const popupImg = localStorage.getItem('img_popup_landingpage')
  // const [modal, setModal] = useState(false)
  // const toggle = () => setModal(!modal);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     console.log(popupImg)
  //     if (popupImg === null) {
  //       localStorage.setItem('img_popup_landingpage', "shown")
  //       setModal(true)
  //       setTimeout(() => {
  //         localStorage.removeItem('img_popup_landingpage')
  //       }, 1);
  //     }
  //   }
  //   return () => { isMounted = false }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);

  return (
    <>
      {/* SIMULASI COURSE VIEW */}
      {/* <div>
        <div className='position-relative bg-white p-4' style={{float: 'left', width: '60%', background: '#666', height: '100%', left: '0%', boxSizing: 'border-box'}}>
          <div>
            {(() => {
              const arr = [];
              for (let i = 0; i < 40; i++) {
                arr.push(
                  <div>test</div>
                );
              }
              return arr;
            })()}
          </div>
        </div>
        <div className='bg-white me-8 p-4 overflow-auto' style={{float: 'left', position: 'fixed', right: '0%', width: '30%', maxHeight: '60%'}}>
          <div>
            {(() => {
              const arr = [];
              for (let i = 0; i < 40; i++) {
                arr.push(
                  <div>test</div>
                );
              }
              return arr;
            })()}
          </div>
        </div>
      </div> */}
      {/* <button onClick={toggle}>click me Modal</button>
      <Modal size="fullscreen" isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>
          {t`label.hapus`}
        </ModalHeader>
        <ModalBody>
          <div>ppppp</div>
        </ModalBody>
      </Modal> */}
    </>
  );
}

const DashboardWrapper: FC = () => {
  const role = useSelector<RootState>(({auth}) => auth.role, shallowEqual)
  // const company = useSelector<RootState>(({company}) => company.company, shallowEqual)
  // console.log(company)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{t`menu.dashboard`}</PageTitle>
      {role === 'pelamar' ?
        <DashboardPelamarWrapper />  : 
      role === 'vendor' ?
        <DashboardVendor /> :
        <DashboardPage />
      }
    </>
  )
}

export {DashboardWrapper}
